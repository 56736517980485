<template>
  <div>
      <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <h4>แก้ไขลิงค์ Fanpage&Post</h4>
          <br>
          <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ชื่อลิงค์ Fanpage"
              v-model="nameFanpage" name="username" />
          </div>
          <div class="vx-col md:w-1/2 w-full mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ลิงค์ Fanpage"
              v-model="Fanpage" name="username" />
          </div>
          <div class="vx-col md:w-1/2 w-full mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ชื่อลิงค์ Post" v-model="namePost"
              name="post" />
          </div>
          <div class="vx-col md:w-1/2 w-full mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ลิงค์ Post" v-model="post"
              name="post" />
          </div>
          <div v-if="nameWeb === 'SLOTVVIP888'" class="vx-col md:w-1/2 w-full mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ชื่อลิงค์ tiktok" v-model="nameTiktok"
              name="tiktok" />
          </div>
          <div v-if="nameWeb === 'SLOTVVIP888'" class="vx-col md:w-1/2 w-full mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ลิงค์ tiktok" v-model="tiktok"
              name="tiktok" />
          </div>
          </div>
          <div>
             <div class="mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="เครคิตที่แจก" v-model="creditFree"
              name="creditFree" />
          </div>
          <div class="mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ลิมิตต่อวัน" v-model="userLimit"
              name="userLimit" />
          </div>
          <div class="mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ยอดขั้นต่ำที่ถอนได้" v-model="turnover"
              name="tuanover" />
          </div>
          <div class="mt-8">
            <vs-input :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" class="w-full"  label-placeholder="ยอดเงินที่ได้รับ" v-model="withdraw"
              name="withdraw" />
          </div>
          </div>

          <vs-button :disabled="$store.state.AppActiveUser.permissions.creditfree.action ? false : true" color="success" type="relief" class="mt-8" @click="editlink()">แก้ไข</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios'
import log from '../../log'
export default {
  data () {
    return {
      post:'',
      namePost: '',
      Fanpage:'',
      nameFanpage: '',
      creditFree: '',
      userLimit: '',
      turnover: '',
      withdraw: '',
      tiktok: '',
      nameTiktok: '',
      info:[],
      status:[],
      log_info: '',
      nameWeb: process.env.VUE_APP_TITLE
    }
  },
  async mounted () {
    await axios
      .get('creditfree/showinfo')
      .then(response => (this.info = response.data))
    this.namePost = this.info[0].name_post
    this.post = this.info[0].post
    this.nameFanpage = this.info[0].name_fanpage
    this.Fanpage = this.info[0].fanpage
    this.creditFree = this.info[0].credit
    this.userLimit = this.info[0].perday
    this.turnover = this.info[0].turnover
    this.withdraw = this.info[0].withdraw
    this.tiktok = this.info[0].tiktok
    this.nameTiktok = this.info[0].name_tiktok
  },
  methods:{
    async editlink () {
      await axios
        .post('creditfree/editinfo', {
          fanpage:this.Fanpage,
          post:this.post,
          credit:this.creditFree,
          turnover:this.turnover,
          withdraw:this.withdraw,
          perday: this.userLimit,
          nameFanpage : this.nameFanpage,
          namePost : this.namePost,
          tiktok : this.tiktok,
          nameTiktok : this.nameTiktok
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        await this.comparedata()
        await log.agent('', 'Edit_CreditFree', 0, this.log_info)
        this.$vs.notify({
          time:4000,
          color: 'success',
          position:'top-right',
          icon:  'check_box',
          title: 'ทำรายการสำเร็จ',
          text: this.status.info
        })
      } else {
        this.$vs.notify({
          time:4000,
          color: 'danger',
          position:'top-right',
          icon:  'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: this.status.info
        })
      }
    },
    async comparedata () {
      if (this.info[0].post !== this.post) {
        this.log_info += `โพส : ${  this.info[0].post  } > ${  this.post} `
      }
      if (this.info[0].fanpage !== this.Fanpage) {
        this.log_info += `แฟนเพจ : ${ this.info[0].fanpage  } > ${  this.Fanpage} `
      }
      if (this.info[0].credit !== this.creditFree) {
        this.log_info += `เครคิตที่แจก : ${ this.info[0].credit  } > ${  this.creditFree} `
      }
      if (this.info[0].perday !== this.userLimit) {
        this.log_info += `ลิมิตต่อวัน : ${ this.info[0].perday  } > ${  this.userLimit} `
      }
      if (this.info[0].turnover !== this.turnover) {
        this.log_info += `ยอดขั้นต่ำที่ถอนได้ : ${ this.info[0].turnover  } > ${  this.turnover} `
      }
      if (this.info[0].withdraw !== this.withdraw) {
        this.log_info += `ยอดเงินที่ได้รับ : ${ this.info[0].withdraw  } > ${  this.withdraw} `
      }
    }
  }


}
</script>

<style>

</style>
